@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.general {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: $margin-md;
  .chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $margin-sm $margin-md;
    background-color: $white;
    border-radius: 10px;
    &:not(:last-child) {
      margin-right: $margin-md;
    }
  }
}

.title {
  position: sticky;
  left: 0;
  text-transform: uppercase;
} 

.bowl {

  .containerCharts {
    display: flex;
    margin-bottom: $margin-md;
    .chart {
      background-color: $white;
      padding: $margin-sm $margin-md;
      border-radius: 10px;
      &:not(:last-child) {
        margin-right: $margin-md;
      }

    }
  }
}