@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.data {
  overflow-x: auto;

  padding: $margin-xs;
  margin: calc( -1 * $margin-xs);
  @media screen and (min-width: $lg-screen) {
    padding: $margin-lg;
    margin: calc( -1 * $margin-lg);
  }

  .header {
    position: sticky;
    z-index: 2;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter {
      display: flex;
      justify-content: center;
      align-items: center;
      .selectView {
        margin: 0 $margin-sm;
      }
  
      .selectView,
      .selectSpecies {
        width: 200px;
      }
    }
  }
  nav {
    position: sticky;
    z-index: 1;
    left: 0;
    margin-bottom: $margin-md;
    ul {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        list-style: none;
        font-family: 'DMSans-Bold';
        font-size: $medium;
        cursor: pointer;
        pointer-events: inherit;
        color: $text-color;

        &.selected {
          color: $primary-color;
        }

        &:not(:last-child) {
          margin-right: $margin-md;
        }
      }
    }
  }
}
