@import "../../styles/_variables.scss";

.selectDateRange {
  display: flex;
  align-items: center;
  h3 {
    position: relative;
    top: 1px;
    text-transform: capitalize;
    margin: 0 $margin-xs;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: $text-color;
    padding: 0;
    &.disabled {
      opacity: 0.5;
    }
  }

}