@import "../../styles/_variables.scss";

.layout{
  display: flex;
  flex: 1;
  max-height: 100%;
  overflow: auto;
  background-color: $bg-color;

  .container {
    flex: 1;
    overflow-y: auto;
    padding: $margin-xs;
    @media screen and (min-width: $lg-screen) {
      padding: $margin-lg;
    }
  }
}