@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  display: flex;
  align-items: center;
  >div {
    width: 150px;
    margin-right: $margin-xs;
  }

  button {
    height: 37px;
    padding: 0 $margin-sm;
    border: none;
  }
}