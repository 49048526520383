@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.customTooltip {
  background-color: $white;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 8px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  max-width: 200px;
  p {
    margin: 0;
  }
}