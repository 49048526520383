@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.animalName {
  position: sticky;
  z-index: 0;
  left: 0;
  text-transform: uppercase;
}


.chart {
  display: inline-block;
  background-color: $white;
  padding: $margin-sm $margin-md;
  border-radius: 10px;

  &.group {
    margin-bottom: $margin-md;
  }

  &:not(:last-child) {
    margin-right: $margin-md;
  }
}

.animal { 
  .containerCharts {
    display: flex;
    margin-bottom: $margin-md;
  }
}