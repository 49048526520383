@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.customTooltip {
  background-color: $white;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 8px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  p {
    margin: 0;
  }

  ul {
    padding: 0;
    li {
      list-style: none;
    }
  }

}

.customLegend {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  .legendItem {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .legendColor {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
  .legendLabel {
    font-size: 14px;
  }
}






