@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.forgot-password { 
  width: 100%;
  background-color: $white;

  .top {
    position: relative;
    z-index: 0;
    background-color: $black;
    background-image: url('../../assets/images/pattern.png');
    background-position: center;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      background-color: white;
      height: 80px;
      width: 100%;
      border-radius: 80px 0 0 0;
    }

    &:before {
      content: '';
      position: absolute;
      right: -1px;
      bottom: 80px;
      display: block;
      background-size: contain;
      background-image: url('../../assets/images/round-corner.svg');
      height: 80px;
      width: 80px;
      border-radius: 80px 0 0 0;
    }
    

    .container {
      @include container();
      img {
        display: block;
        margin: 40px auto 120px auto;
      }
    }
  }

  .container {
    position: relative;
    z-index: 1;
    @include container();
    padding: $margin-lg;
    h3 {
      font-family: "DMSans-Bold";
      color: $text-color;
      margin: -50px auto $margin-md auto;
      max-width: 485px;
      width: 100%;
      +div {
        margin: 0 auto;
      }
    }
  }
}
