@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.sidebar {
  display: flex;
  flex-direction: column;
  width: 202px;
  background-color: $white;
  .logo {
    padding: $margin-md;
    padding-bottom: 0;
    img {
      width: 100%;
    }  
  }
  .navigation {
    padding: $margin-lg;
    ul {
      padding: 0;
      margin: $margin-lg 0;
    }
    ul li {
      list-style: none;
      &:not(:last-child) {
        margin-bottom: $margin-xs;
      }
      a {
        position: relative;
        font-family: 'DMSans-Bold';
        font-size: $medium;
        text-decoration: none;
        color: $text-light;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 53%;
          left: -15px;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: $text-light;
          transform: scale(0, 0) translate(0, -50%);
        }

        &.disabled {
          pointer-events: none;
        }

        &.active {
          color: $text-color;
          &:after {
            background-color: $text-color;
            transform: scale(1, 1) translate(0, -50%);
          }
        }
      }
    }
  }

  .bottom {
    margin-top: auto;
    padding: $margin-md;
    text-align: center;

    .profilePicture {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      min-width: 100px;
      height: 100px;
      min-height: 100px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      margin: -50px auto $margin-sm auto;
      background-color: $light-grey;

      p {
        position: absolute;
        top: 50%;
        font-family: 'DMSans-Bold';
        color: $white;
        font-size: 40px;
        transform: translate(0, -50%);
      }

    }

    .logout {
      display: flex;
      align-items: center;
      margin-top: $margin-md;
      padding-top: $margin-md;
      border-top: solid 1px $light-grey;

      button {
        display: flex;
        align-items: center;
        padding: 0;
        margin-right: $margin-xs;
        width: 45px;
        height: 45px;
      }
      p {
        color: $text-light;
        margin: 0;
      }
    }

  }

}