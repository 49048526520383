@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.domainStats {
  background-color: $white;
  padding: $margin-sm $margin-md;
  border-radius: 10px;
  overflow: hidden;

  img {
    margin-bottom: $margin-sm;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $regular;
    line-height: $regular;
    // margin-bottom: 0;
  }

  .stats {
    display: flex;
    width: 100%;
    height: 6px;
    margin-bottom: $margin-md;
    >div {
      position: relative;
      height: 6px;
      &:first-child {
        border-radius: 3px 0 0 3px;
      }
      &:last-child {
        border-radius: 0 3px 3px 0;
      }
      &.empty {
        background-color: $light-grey;
        border-radius: 3px;
      }
      &.critic {
        background-color: $stat-critical;
        color: $stat-critical;
      }
      &.moderate {
        background-color: $stat-warn;
        color: $stat-warn;
      }
      &.great {
        background-color: $stat-great;
        color: $stat-great;
      }
      p {
        position: absolute;
        font-family: 'DMSans-Bold';
        top: 10px;
        right: 0;
        transform: translateX(50%);
        margin: 0;
        color: currentColor;
      }
    }
  }
}