@mixin container {
  margin: auto;
  width: 100%;
  max-width: 1024px;
  padding: $margin-md;
  margin: 0 auto;
}

@mixin page {
  position: relative;
  margin: auto;
  flex: 1;
  padding: $margin-md;
  margin: 0 auto;
  overflow: auto;
  max-height: 100%;
}

@mixin backButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  display: block;
  font-size: 40px;
  width: 25px;
  height: 45px;
  color: $primary-color;
  margin-bottom: $margin-xs;
  cursor: pointer;
  svg {
    position: relative;
    left: -8px;
    width: 40px;
    height: 40px;
  }
}

@mixin initials {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: $margin-xs;
  border-radius: 50%;
  background-color: $primary-color;
  border: 2px solid #f0f0f0;
  background-position: center;
  background-size: cover;

  > span {
    position: relative;
    font-family: "DMSans";
    font-size: $medium;
    color: $white;
  }
}

@mixin containerField {
  margin-bottom: $margin-lg;

  &.grid2Tags ul{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &.gridNotes ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    button {
      padding-left: $margin-xs;
      padding-right: $margin-xs;
    }
  }

}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin dotLoader() {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: $primary-color;
  border-radius: 7px;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: rotate;
  margin-right: $margin-sm;
}
@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  50% {
    transform: rotateX(-180deg) rotateY(0);
  }
  100% {
    transform: rotateX(-180deg) rotateY(-180deg);
  }
}
