@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.home{
  display: flex;
  .col {
    &:first-child {
      flex: 0.45;
    }
    &:last-child {
      flex: 0.65;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-md;
      h4 {
        margin: 0;
      }
    }

  }

  .col:first-child {
    padding-right: $margin-lg;
    .animals {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
      margin: 0;
      grid-column-gap: $margin-md;
      grid-row-gap: $margin-md;
    // grid-template-rows: repeat();
      li {
        list-style: none;
        >div {
          background-color: $white;
          padding: $margin-md;
          box-sizing: border-box;
          border-radius: 10px;
          p {
            font-family: "DMSans-Bold";
            font-size: $medium;
            margin: 0;
          } 
        }
      }
    }
  }

  .col:nth-child(2) .selectSpecies {
    margin-bottom: $margin-md;
    width: 200px;
    margin-left: auto;
  }

  .col:nth-child(2) .containerNote {
    position: relative;
    margin-bottom: 50px;

    .note {
      background-color: $white;
      border-radius: $radius;

      .content {
        padding: $margin-sm;
        p {
          line-height: 18px;
        }
      }
      
      .author {
        color: $text-light;
        font-style: italic;
      }

    }

    &:not(.open) .note{
      pointer-events: none;
    }

    &.open {
      margin-bottom: 0;
      textarea {
        padding: $margin-sm !important;
      }
    }
    .add {
      position: absolute;
      right: 5%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 0;
      text-align: center;
      background-color: $primary-color;
      border-color: $primary-color;
      svg {
        margin-left: 5px;
      }
    }

    .submit {
      width: 100%;
      margin-top: $margin-md;
      background-color: $primary-color;
      border-color: $primary-color;
    }

  }

  .col:nth-child(2) .statsTiles {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $margin-sm;
  }
}
