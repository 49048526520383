@import "../../../../styles/_variables.scss";

.signin-form {
  max-width: 485px;

  .form {
    margin-bottom: $margin-lg;
    .container-field {
      margin: 0 0 15px 0;
    }
    .message-error {
      font-family: 'DMSans';
      color: $warn;
      font-size: $regular;
    }
  }

  .container-link {
    margin-bottom: $margin-md;
    p {
      font-size: $regular;
      color: $text-color;
      a {
        font-size: $regular;
        text-decoration: none;
        color: $primary-color;
      }
    }
  }

  .containerSubmit {
    a {
      display: block;
      position: relative;
      color: $text-color;
      margin-top: $margin-sm;
    }
  }
}
